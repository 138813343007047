.select
  display: inline-block
  max-width: 100%
  position: relative
  vertical-align: top
  &:not(.is-multiple)
    height: $input-height
  &:not(.is-multiple):not(.is-loading)
    &::after
      @extend %arrow
      border-color: $input-arrow
      right: 1.125em
      z-index: 4
  &.is-rounded
    select
      border-radius: $radius-rounded
      padding-left: 1em
  select
    @extend %input
    cursor: pointer
    display: block
    font-size: 1em
    max-width: 100%
    outline: none
    &::-ms-expand
      display: none
    &[disabled]:hover,
    fieldset[disabled] &:hover
      border-color: $input-disabled-border-color
    &:not([multiple])
      padding-right: 2.5em
    &[multiple]
      height: auto
      padding: 0
      option
        padding: 0.5em 1em
  // States
  &:not(.is-multiple):not(.is-loading):hover
    &::after
      border-color: $input-hover-color
  // Colors
  @each $name, $pair in $colors
    $color: nth($pair, 1)
    &.is-#{$name}
      &:not(:hover)::after
        border-color: $color
      select
        border-color: $color
        &:hover,
        &.is-hovered
          border-color: bulmaDarken($color, 5%)
        &:focus,
        &.is-focused,
        &:active,
        &.is-active
          box-shadow: $input-focus-box-shadow-size bulmaRgba($color, 0.25)
  // Sizes
  &.is-small
    +control-small
  &.is-medium
    +control-medium
  &.is-large
    +control-large
  // Modifiers
  &.is-disabled
    &::after
      border-color: $input-disabled-color
  &.is-fullwidth
    width: 100%
    select
      width: 100%
  &.is-loading
    &::after
      @extend %loader
      margin-top: 0
      position: absolute
      right: 0.625em
      top: 0.625em
      transform: none
    &.is-small:after
      font-size: $size-small
    &.is-medium:after
      font-size: $size-medium
    &.is-large:after
      font-size: $size-large
