@media screen and (min-width: 1024px) {
  .select.is-medium {
    font-size: 2rem !important;
  }

  .button.is-large {
    font-size: 3rem !important;
  }

  .question {
    margin-bottom: 6rem;
  }
}

@media screen and (max-width: 1023px) {
  .question {
    margin-bottom: 2rem;
  }
}

@import "~bulma";

.exit {
  margin-top: 2rem;
}

body {
  background-color: #e9f3e5;
}

.column, .field {
  max-width: 100%;
}

.history {
  margin-top: 2rem;
}

.title {
  text-align: center;
  margin-bottom: 6rem !important;
}

.vcentered {
  display: flex;
  align-items: center;
}

.full-height {
  min-height: 100vh;
}

.centered {
  display: flex;
  justify-content: center;
}

.main-select {
  margin-bottom: 4rem;
}

.question {
  padding: 8px 0;
  text-align: center;
}

.exit {
  display: flex;
  justify-content: flex-end;
}
